import type { UpcomingPages } from '@components/app/SiteStaffPortal/UpcomingPages'
import { RoutePrefix } from '.'

const TrialProRoutes = {
  admin: `${RoutePrefix.TRIAL_PROS}/admin`,
  callRoom: `${RoutePrefix.TRIAL_PROS}/call-room`,
  matches: `${RoutePrefix.TRIAL_PROS}/matches`,
  matchesProfile: (idToken: string) =>
    `${RoutePrefix.TRIAL_PROS}/matches/${idToken}`,
  matchesUnverified: `${RoutePrefix.TRIAL_PROS}/matches/unverified`,
  onboarding: (nctId: string) =>
    `${RoutePrefix.TRIAL_PROS}/onboarding/${nctId}`,
  onboardingCompletion: `${RoutePrefix.TRIAL_PROS}/onboarding/completion`,
  onboardingSelectTrial: `${RoutePrefix.TRIAL_PROS}/onboarding/select-trial`,
  onboardingWelcome: `${RoutePrefix.TRIAL_PROS}/onboarding/welcome`,
  patientDossier: (idToken: string) =>
    `${RoutePrefix.TRIAL_PROS}/patient-handoff/${idToken}`,
  preScreenerSettings: `${RoutePrefix.TRIAL_PROS}/pre-screener`,
  redirect: `${RoutePrefix.TRIAL_PROS}/redirect`,
  registry: `${RoutePrefix.TRIAL_PROS}/registry`,
  registryRevamp: `${RoutePrefix.TRIAL_PROS}/registry-revamp`,
  settings: `${RoutePrefix.TRIAL_PROS}/settings`,
  upcoming: (slug: UpcomingPages) =>
    `${RoutePrefix.TRIAL_PROS}/upcoming/${slug}`,
}

export default TrialProRoutes
