'use client'

import { PatientBurgerMenu } from '@components/app/NavBar/PatientBurgerMenu'
import { useSignedInUser } from '@components/hooks/useSignedInUser'
import { useUserRoles } from '@components/hooks/useUserRoles'
import type { ColorGroup } from '@lib/colors'
import Routes from '@lib/routes'
import { useEffect, useState } from 'react'
import BecomeTrialProButton from '../Button/BecomeTrialProButton'
import { SignInBtn } from '../Button/SignInBtn'
import SiteStaffPortalButton from '../Button/SiteStaffPortalButton'
import { BurgerButton } from './BurgerButton'

export const BurgerButtonVariants = ({
  compactAccountButtons,
  menuButtonColorGroup,
  open,
  setOpen,
  showBurger,
  showSignIn,
}: {
  compactAccountButtons?: boolean
  menuButtonColorGroup: ColorGroup
  open: boolean
  setOpen: (open: boolean) => void
  showBurger: boolean
  showSignIn: boolean
}) => {
  const signedInUser = useSignedInUser()
  const isSignedIn = Boolean(signedInUser)

  const { isPatient, isTrialPro } = useUserRoles()
  const [shouldShowPatientBurgerMenu, setShouldShowPatientBurgerMenu] =
    useState(false)
  useEffect(() => {
    setShouldShowPatientBurgerMenu(isSignedIn && isPatient)
  }, [])

  return (
    <>
      {open && showSignIn && !isSignedIn && (
        <div className='fixed bottom-0 left-0 z-20 flex bg-white sm:relative sm:bg-transparent'>
          <SignInBtn
            colorGroup='white'
            label='Log In'
            route={Routes.signIn()}
          />
          <SignInBtn
            colorGroup='black'
            label='Sign Up'
            route={Routes.signUp()}
          />
        </div>
      )}
      {open && isSignedIn && isTrialPro && !isPatient && (
        <SiteStaffPortalButton compact={compactAccountButtons} />
      )}
      {open && isSignedIn && shouldShowPatientBurgerMenu && (
        <BecomeTrialProButton compact={compactAccountButtons} />
      )}
      {showBurger && shouldShowPatientBurgerMenu && (
        <PatientBurgerMenu colorGroup={menuButtonColorGroup} />
      )}
      {showBurger && !shouldShowPatientBurgerMenu && (
        <BurgerButton
          colorGroup={menuButtonColorGroup}
          onClick={() => {
            setOpen(!open)
          }}
          open={open}
        />
      )}
    </>
  )
}
