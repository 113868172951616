'use client'

import { useIsTabletOrMobileScreen } from '@components/hooks/useIsMobileScreen'
import useRelativeScroll from '@components/hooks/useRelativeScroll'
import type { PageWidth } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { ResponsivePageMargin } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { classMerge } from '@components/utilities/classMerge'
import type { BackgroundColor, ColorGroup, ColorHexCode } from '@lib/colors'
import { colors } from '@lib/colors'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import { BurgerButtonVariants } from './BurgerButtonVariants.client'
import { ExpandedNavbarMenuContent } from './ExpandedNavbarMenuContent'
import { NavBarLogo } from './NavBarLogo'
import type { ActiveLink } from './NavigationLinks'
import { NavigationLinks } from './NavigationLinks'
import TransparentBackground from './TransparentBackground'

export type NavBarProps = {
  backgroundColor?: BackgroundColor
  children?: React.ReactNode
  className?: string
  compactAccountButtons?: boolean
  containerClassName?: string
  disableLogoLink?: boolean
  height?: string
  hiddenCta?: boolean
  includeNavigationLinks?: boolean
  linkActive?: ActiveLink
  logoColor?: ColorHexCode
  logoImageId?: string
  maxWidth?: PageWidth
  menuButtonColorGroup?: ColorGroup
  navbarMenu?: React.ReactNode
  shadow?: boolean
  showBurger?: boolean
  showForProviders?: boolean
  showLogo?: boolean
  showSignIn?: boolean
  style?: CSSProperties // Prefer using other props like className
  transparentBackground?: boolean
  trial?: TrialAssociation
  unstyled?: boolean
}

const NAVBAR_HEIGHT = 'h-[70px] pb-[70px] md:h-[76px] md:pb-[76px]'

/**
 * Top navigation header for the page.
 */

export function NavBar({
  backgroundColor = 'bg-white',
  children,
  className,
  compactAccountButtons,
  containerClassName,
  disableLogoLink = false,
  height = NAVBAR_HEIGHT,
  includeNavigationLinks = true,
  linkActive,
  logoColor = colors.black,
  logoImageId,
  maxWidth = 'max-w-screen-xl',
  menuButtonColorGroup = 'white',
  navbarMenu,
  shadow = false,
  showBurger = true,
  showForProviders = false,
  showLogo = true,
  showSignIn = true,
  style,
  transparentBackground = false,
  unstyled = false,
}: NavBarProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const scroll = useRelativeScroll(ref.current)
  const [open, setOpen] = useState<boolean>(false)
  const isMobile = useIsTabletOrMobileScreen()
  const prevPosition = useRef(0)

  const transitionPoint = 50

  useEffect(() => {
    if (
      open &&
      scroll.y > transitionPoint &&
      scroll.y !== prevPosition.current &&
      !isMobile
    ) {
      setOpen(false)
    }

    prevPosition.current = scroll.y
  }, [isMobile, open, scroll.y, transparentBackground])

  return (
    <>
      <div className={height} />
      <div id='navbar-wrapper' ref={ref}>
        <TransparentBackground
          backgroundColor={backgroundColor}
          height={height}
          isMobile={isMobile}
          open={open}
          scroll={scroll}
          shadow={shadow}
          style={style}
          transitionPoint={transitionPoint}
          transparentBackground={transparentBackground}
        />
        <div
          className={classMerge(
            open && 'h-full md:h-auto',
            'fixed left-0 top-0 z-navbarContainer flex w-full justify-center',
            containerClassName,
          )}
          id='navbar'
        >
          <ResponsivePageMargin
            className={className}
            contentWidth={maxWidth}
            unstyled={unstyled}
          >
            <div className='mx-auto flex w-full flex-row items-center justify-between'>
              {showLogo && (
                <NavBarLogo
                  color={logoColor}
                  disableLink={disableLogoLink}
                  logoImageId={logoImageId}
                />
              )}
              {children}
              <div className='flex items-center py-2'>
                {navbarMenu ? (
                  navbarMenu
                ) : (
                  <>
                    {includeNavigationLinks && (
                      <div
                        className={classMerge('hidden lg:block', {
                          'mr-4': !open,
                        })}
                      >
                        <NavigationLinks
                          linkActive={linkActive}
                          navExpanded={open}
                          showForProviders={showForProviders}
                        />
                      </div>
                    )}
                    <BurgerButtonVariants
                      compactAccountButtons={compactAccountButtons}
                      menuButtonColorGroup={menuButtonColorGroup}
                      open={open}
                      setOpen={setOpen}
                      showBurger={showBurger}
                      showSignIn={showSignIn}
                    />
                  </>
                )}
              </div>
            </div>
            <ExpandedNavbarMenuContent
              isOpen={open}
              maxWidth={maxWidth}
              setIsOpen={setOpen}
            />
          </ResponsivePageMargin>
        </div>
      </div>
    </>
  )
}
