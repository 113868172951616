import Button from '@components/primitives/Button'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { ColorGroup } from '@lib/colors'
import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import { usePathname, useSearchParams } from 'next/navigation'
import { getColorConfigFromColorGroup } from './helpers/getColorConfigFromColorGroup'

export const SignInBtn = ({
  colorGroup = 'black',
  label,
  route,
}: {
  colorGroup: ColorGroup
  label: string
  route: string
}) => {
  const path = usePathname()
  const query = useSearchParams()
  const queryObject = Object.fromEntries(query?.entries() ?? [])

  const href = urlWithQueryParams(route, {
    redirect: path ?? '',
    ...queryObject,
  })

  const colorConfig = getColorConfigFromColorGroup(colorGroup)
  const borderClass = `border-${colorConfig.borderColor}`

  return (
    <Link className='m-2 flex-1 sm:m-0 sm:mr-2' href={href}>
      <Button
        className={classMerge(`h-[50px] w-32 rounded-full ${borderClass}`, {
          'border-2': colorConfig.borderColor !== 'transparent',
        })}
        color={colorGroup}
      >
        <Text
          className={`px-4  ${colorConfig.iconColor}`}
          styleName='p-small-strong'
          value={label}
        />
      </Button>
    </Link>
  )
}
